.navbarWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.navbarItem {
    text-transform: uppercase;
    font-family: var(--sans);
    font-size: 1.4em;
    font-weight: 500;
    color: var(--deep-purple);
    cursor: pointer;
    transition: all .1s ease;
}

.navbarItem:hover {
    margin-top: -4px;
    text-shadow: 4px 4px 3px var(--light-grey);
}

.activeItem,
.navbarItem:active {
    color: var(--orange);
    margin-top: 0;
    text-shadow: none;
    transition: none;
}

.navbarItem:active {
    color: var(--deep-purple);
}

.activeItem:active {
    color: var(--orange);
}

