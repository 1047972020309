.pageVisible {
    opacity: 1;
}

.pageInvisible {
    opacity: 0;
    transition: all ease .3s;
}

.mainImgSuperWrap {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainImgWrap {
    padding: 0 15px;
    opacity: 0;
    height: 150vh;
    background-image: url('../../../assets/images/bgr/kmns-main.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100%;
    transition: all 5s ease;
}

.startImgVisible {
    opacity: 1;
    height: 100vh;
}

.mainTitleWrap {
    font-weight: 400;
    margin: 4.5vw 0 0 5vw;
    font-family: var(--sans);
}

.mainTitle {
    font-size: 5.5vw;
    font-weight: 600;
    transform: scaleY(1.2);
    color: transparent;
    user-select: none;

}

.letterShadow {
    color: white;
    text-shadow: 0 0 15px #a589fa;
    animation: letshad 1s normal;
    transition: 1s ease all;
}

@keyframes letshad {
    0% {
        text-shadow: 0 0 10px #fff, 0 0 30px #fff, 0 0 50px #fff;
        opacity: 0;
    }
    50% {
        text-shadow: 0 0 10px #fff, 0 0 30px #fff, 0 0 50px #00e4ff, 0 0 70px #fff;
        opacity: 1;
    }
    100% {
        text-shadow: 0 0 15px #a589fa;
    }
}

.letterShadow:hover {
    text-shadow: 0 0 10px #fff, 0 0 30px #fff, 0 0 50px #00e4ff, 0 0 70px #fff;
    transition: .2s ease all;
}

.mainSubTitle {
    font-size: 1.46vw;
    color: var(--deep-purple);
    margin-bottom: 10px;
    opacity: 0;
    transition: .7s ease all;
    transform: scaleY(1.2);
}

.mainSubTitleVisible {
    opacity: 1;
}

.prologWrap {
    min-height: 100vh;
    padding: 50px 70px;
    background-image: url('../../../assets/images/bgr/kmns-main-oleni.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.prologWrap p {
    margin-bottom: 1em;
    font-size: 1.1em;
}

.scrollArrowWrap {
    margin: 3vw 0 0 100px;
    animation: arrow 6s infinite;
    opacity: 0;
    transition: .5s ease all;
}

.scrollArrowWrapVisible {
    opacity: 1;
}

@keyframes arrow {
    13% {
        padding-top: 0;
    }
    20% {
        padding-top: 15px;
    }
    25% {
        padding-top: 5px;
    }
    32% {
        padding-top: 15px;
    }
    45% {
        padding-top: 0;
    }
}

.scrollArrow {
    margin: 0;
    height: 100px;
    width: fit-content;
    padding: 0 10px;
    display: flex;
    align-items: flex-end;
    gap: 3px;
    cursor: pointer;
}

.arrowLeftLine,
.arrowRightLine,
.arrowMidLine {
    background-color: var(--semi-light-grey);
    width: 2px;
    box-shadow: 0 0 15px 5px white;
}

.arrowLeftLine,
.arrowRightLine {
    height: 25px;
    margin-bottom: -2px;
}

.arrowLeftLine {
    transform: rotate(-25deg);
}

.arrowRightLine {
    transform: rotate(25deg);
}

.arrowMidLine {
    height: 100px;
}

.startButton {
    font-family: var(--sans);
    font-size: 1.2em;
    margin-top: 30px;
    border: 1px solid var(--semi-light-grey);
    padding: 20px 30px;
    border-radius: 5px;
    background-color: #ffffff33;
    transition: .2s ease all;
    cursor: pointer;
    font-weight: 300;
}

.startButton:hover {
    background-color: var(--deep-purple);
    color: white;
}

@media screen and (max-width: 991px) {
    .mainTitle {
        font-size: 3.5em;
    }

    .mainSubTitle {
        font-size: .93em;
    }

    .scrollArrowWrap {
        margin: 20px 0 0 100px;
    }

    .prologWrap {
        padding: 50px;
    }
}

@media screen and (max-width: 640px) {
    .mainImgWrap {
        background-image: url('../../../assets/images/bgr/kmns-main-mobile.png');
        background-position: top;
    }

    .mainTitleWrap {
        margin: 20px 0 0 0;
    }

    .mainTitle {
        font-size: 10vw;
        text-align: center;
    }

    .mainSubTitle {
        font-size: .9em;
        text-align: center;
    }
}

@media screen and (max-width: 480px) {
    .prologWrap {
        padding: 30px 30px 50px;
    }

    .scrollArrowWrap {
        margin-left: 70px;
    }

    .startButton {
        margin-top: 20px;
    }
}
