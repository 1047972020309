.footerWrap {
    width: 100%;
    height: 80px;
    background-color: var(--ex-light-beige);
    border-top: 1px solid var(--light-beige);
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    transition: .5s ease all;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
}

.mediaschool {
    display: block;
    height: 60px;
}

.nornikel {
    display: block;
    height: 30px;
}

.gorod {
    display: block;
    height: 43px;
}

.footerWrap img {
    height: 100%;
}

@media screen and (max-width: 850px) {
    .footerAddresses {
        font-size: 0.75em;
    }

    .footerWrap img {
        height: 90%;
    }
}

@media screen and (max-width: 768px) {
    .footerWrap img {
        height: 100%;
    }
}

@media screen and (max-width: 640px) {
    .footerWrap {
        display: none;
    }
}
