.cardWrap {
    width: 22%;
    cursor: pointer;
    position: relative;
}

.cardWrapDown {
    margin-top: 50px;
}

.cardWrapImg {
    width: 100%;
}

.cardFrame {
    position: absolute;
    opacity: 0;
    width: 100%;
    top: 0;
    left: 0;
    transition: all ease .2s;
}

.cardFrameHover {
    opacity: 1;
}

.cardTitle {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 3px solid var(--light-grey);
    font-size: .8em;
    font-weight: 500;
    font-family: var(--sans);
    padding: 3px 7px 5px;
    background-color: var(--back-card-title);
    color: var(--brown-grey);
    border-radius: 5px;
    transition: all ease .2s;
}

.cardTitleHover {
    bottom: 3px;
    left: -1px;
}