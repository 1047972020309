.loadersWrap {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 70px;
    opacity: 0;
    transition: .5s all ease;
}

.visible {
    opacity: 1;
}

.loader {
    font-family: var(--sans);
    font-weight: 200;
    font-size: 20px;
    text-align: center;
}

.loader:before {
    content: "...идет загрузка, подождите..."
}

.loaderClock {
    width: 40px;
    aspect-ratio: 1;
    border: 1px solid var(--blue);
    box-sizing: border-box;
    color: var(--dark-grey);
    background: radial-gradient(circle 3px, currentColor 95%, #0000),
    linear-gradient(currentColor 50%, #0000 0) 50%/3px 80% no-repeat,
    linear-gradient(90deg, currentColor 50%, #0000 0) 50%/60% 3px no-repeat;
    position: relative;
}

.loaderClock:before {
    content: "";
    position: absolute;
    height: 50px;
    inset: 100% 10% auto;
    background: radial-gradient(circle closest-side at 50% calc(100% - 8px), var(--deep-blue) 92%, #0000),
    linear-gradient(var(--blue), var(--blue)) top/1px 80% no-repeat;
    transform-origin: top;
    animation: l2 2s infinite cubic-bezier(0.5, 200, 0.5, -200);
}

@keyframes l2 {
    100% {
        transform: rotate(0.4deg)
    }
}