.headerWrap {
    position: fixed;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 100%;
    background-color: var(--ex-light-beige);
    border-bottom: 1px solid var(--light-beige);
}

