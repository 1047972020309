.peopleWrap {
    padding: 100px 50px 140px;
    position: relative;
}

.bgr {
    position: fixed;
    top: 80px;
    left: 0;
    background: url('../../../assets/images/bgr/people-bgr.png');
    width: 100%;
    height: 100%;
}

.cardsWrap {
    margin: 0 auto;
    max-width: 1100px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 4%;
}