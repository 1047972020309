.articleWrap {
    position: relative;
}

.titleWrap {
    width: 80%;
    font-family: var(--title-font);
    margin-bottom: 20px;
    font-size: 2.5em;
    color: var(--brown-grey);
}

.nameRoleWrap {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.nameWrap {
    font-family: var(--sans);
    font-size: 1.2em;
    text-transform: uppercase;
}

.roleWrap {
    font-style: italic;
    font-family: var(--sans);
}