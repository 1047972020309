.commonPageWrap {
    opacity: 0;
    min-height: 100vh;
    position: relative;
}

.pageVisible {
    opacity: 1;
    transition: all ease .5s;
}

.pageInvisible {
    opacity: 0;
    transition: all ease .3s;
}